import { BoxProps, Flex } from "@chakra-ui/react";
import React from "react";
import NavbarBottom from "./components/NavbarBottom";
import NavbarTop from "./components/NavbarTop";

export interface NavbarProps extends BoxProps {}

const Navbar = (props: NavbarProps) => {
  return (
    <Flex
      direction="column"
      w="full"
      position="fixed"
      top={0}
      zIndex={50}
      {...props}
    >
      <NavbarTop />
      <NavbarBottom />
    </Flex>
  );
};

export default Navbar;
