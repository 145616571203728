import { Box, BoxProps, HStack } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export interface ContactLinkProps extends BoxProps {
  icon: ReactNode;
  text?: string;
  href: string;
}

export const ContactLink = (props: ContactLinkProps) => {
  const {
    icon,
    text,
    href,
    color,
    fontSize = "lg",
    fontStyle = "italic",
    ...restOfProps
  } = props;
  return (
    <Box
      zIndex={2}
      {...restOfProps}
      _hover={{ transform: "scale(1.03)", transition: "all 300ms" }}
      fontSize={fontSize}
      fontWeight={500}
      fontStyle={fontStyle}
    >
      <a href={href} target="_blank">
        <HStack spacing={{ base: 2, md: 0.5, lg: 2 }} fontSize="lg">
          <Box color={color || "primary"} fontSize="xl">
            {icon}
          </Box>
          <Box color={color || "secondary"}>{text}</Box>
        </HStack>
      </a>
    </Box>
  );
};
