import { routes } from "@/lib/routes";
import { Box, BoxProps, HStack } from "@chakra-ui/react";
import { globalHistory } from "@reach/router";
import React from "react";
import { NavbarItem } from "./NavbarItem";

export interface NavbarItemsProps extends BoxProps {}

export const addAbsolutePathIfNotScrollNav = (
  currentPath: string,
  url: string
) => {
  if (url.includes("#") && currentPath == "/") {
    return url;
  } else {
    return "/" + url;
  }
};

export const NavbarItems = (props: NavbarItemsProps) => {
  const { ...restOfProps } = props;
  const path = globalHistory.location.pathname;

  return (
    <Box {...restOfProps}>
      <HStack spacing={10}>
        {Object.values(routes).map((route) => {
          const url = addAbsolutePathIfNotScrollNav(path, route.to);
          return <NavbarItem href={url as any} label={route.label} />;
        })}
      </HStack>
    </Box>
  );
};
