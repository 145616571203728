import { Flex, SimpleGrid, Stack, Text } from "@chakra-ui/layout";
import React from "react";
import { HiArrowRight } from "react-icons/hi";
import { ContactLink } from "../../ContactLinks/components/ContactLink";
import { LogoLink } from "../../LogoLink/LogoLink";
import { Socials } from "../../Socials/Socials";

export interface FooterCompanyProps {
  className?: string;
}

export const FooterCompany = (props: FooterCompanyProps) => {
  const { className = "" } = props;
  return (
    <div className={`${className}`}>
      <SimpleGrid columns={{ sm: 2, lg: 4 }} gap={10} px={{ md: 20, lg: 0 }}>
        <Flex justifyContent={{ base: "center", sm: "flex-start" }} w="full">
          <LogoLink light h={20} />
        </Flex>
        <Stack spacing={4}>
          <Text fontSize="lg" fontWeight="700">
            Sociálne siete
          </Text>
          <Socials inverse />
        </Stack>
        <Stack spacing={4}>
          <Text fontSize="lg" fontWeight="700">
            Sociálne siete
          </Text>
          <Stack spacing={1}>
            <ContactLink
              fontStyle="normal"
              text="Pravidlá Ochrany GDPR"
              href="/gdpr"
              icon={<HiArrowRight />}
            />
            <ContactLink
              fontStyle="normal"
              text="Naša ordinácia"
              href="https://www.google.com/maps/place/Ambulancia+Benjamin+s.r.o/@48.0270463,17.305661,17z/data=!3m1!4b1!4m5!3m4!1s0x476c7f1436fa4c2f:0x498dafba78f8a3c2!8m2!3d48.0270467!4d17.3078549"
              icon={<HiArrowRight />}
            />
          </Stack>
        </Stack>
        <Stack spacing={4}>
          <Text fontSize="lg" fontWeight="700">
            Adresa ordinácie
          </Text>
          <a
            target="_blank"
            href="https://www.google.com/maps/place/Ambulancia+Benjamin+s.r.o/@48.0270463,17.305661,17z/data=!3m1!4b1!4m5!3m4!1s0x476c7f1436fa4c2f:0x498dafba78f8a3c2!8m2!3d48.0270467!4d17.3078549"
          >
            <Stack spacing={1}>
              <Text>Školská 35, Šamorín, 931 01</Text>
              <Text>Slovenská republika</Text>
            </Stack>
          </a>
        </Stack>
      </SimpleGrid>
    </div>
  );
};
