import { Box } from '@chakra-ui/react';
import React from 'react';
import { Container } from '../../Container/Container';

export interface FooterBottomProps {
    className?: string;
}

export const FooterBottom = (props: FooterBottomProps) => {
    const { className = '' } = props;
    const year = new Date().getFullYear();
    return (
        <Box bg='primary' textAlign='center' color='bg' py={6}>
            <Container>Prenat Baby 2021 Všetky práva vyhradené</Container>
        </Box>
    );
};
