import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { ReactNode } from "react-markdown/lib/react-markdown";

export interface HeadingProps extends BoxProps {
  children: ReactNode;
}

export const Heading = (props: HeadingProps) => {
  const {
    children,
    fontSize = { base: "3xl", md: "4xl" },
    fontWeight = "700",
    color = "primary",
    as = "h1",
    ...restOfProps
  } = props;
  return (
    <Box
      {...restOfProps}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      as={as}
      textTransform="uppercase"
    >
      {children}
    </Box>
  );
};
