import { Box } from "@chakra-ui/layout";
import PropTypes from "prop-types";
import React from "react";
import { Footer } from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import "./global.css";

const Layout = ({ children }: any) => {
  return (
    <Box color="text" fontFamily="Source Sans Pro; Open Sans;">
      <Navbar />
      <Box mt={28}>
        <main>{children}</main>
      </Box>
      <Footer />
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
