import { Image } from "@chakra-ui/image";
import { Link } from "gatsby";
import React from "react";
import LogoSvg from "../../../assets/logo.svg";

export interface LogoLinkProps extends React.HTMLAttributes<HTMLElement> {
  light?: boolean;
  h?: number;
}

export const LogoLink: React.FC<LogoLinkProps> = (props: LogoLinkProps) => {
  const { light = false, h = 14 } = props;

  return (
    <Link to={"/"} className={props.className + " flex items-center"}>
      <Image h={h} src={LogoSvg} mt={-2} />
    </Link>
  );
};
