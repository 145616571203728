import { Box, BoxProps, Flex } from "@chakra-ui/react";
import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { ContactLink } from "./components/ContactLink";

export interface ContactLinksProps extends BoxProps {}

export const ContactLinks = (props: ContactLinksProps) => {
  const { color, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Flex
        gridGap={{ base: 2, md: 2, lg: 6, xl: 10 }}
        alignItems={{ base: "center", md: "flex-start" }}
        flexDir={{ base: "column", md: "row" }}
      >
        <ContactLink
          color={color}
          icon={<HiOutlineLocationMarker />}
          text="Školská 35, Šamorín"
          href="https://www.google.com/maps/place/Ambulancia+Benjamin+s.r.o/@48.0270463,17.305661,17z/data=!3m1!4b1!4m5!3m4!1s0x476c7f1436fa4c2f:0x498dafba78f8a3c2!8m2!3d48.0270467!4d17.3078549"
        />
        <ContactLink
          color={color}
          icon={<BsTelephone />}
          text="+421 948 404 993"
          href="tel:+421948404993"
        />
        <ContactLink
          color={color}
          icon={<AiOutlineMail />}
          text="info@tehotenskekurzy.sk"
          href="mailto:info@tehotenskekurzy.sk"
        />
      </Flex>
    </Box>
  );
};
