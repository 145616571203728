import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { ContactLinks } from "../../ContactLinks/ContactLinks";
import { Container } from "../../Container/Container";
import { Socials } from "../../Socials/Socials";

const NavbarTop = () => {
  return (
    <Box bg="primary">
      <Container position="relative">
        <div className="hidden md:flex">
          <Flex py={3} w="full" justifyContent="center">
            <ContactLinks color="white" />
          </Flex>
        </div>
        <Flex
          py={3}
          w="full"
          justifyContent={{ base: "center", md: "flex-end" }}
          position={{ base: "relative", md: "absolute" }}
          top={0}
        >
          <Socials />
        </Flex>
      </Container>
    </Box>
  );
};

export default NavbarTop;
