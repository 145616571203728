import { Box, BoxProps, Flex } from "@chakra-ui/react";
import React from "react";
import { TiSocialFacebook, TiSocialInstagram } from "react-icons/ti";
import { SocialLink } from "./SocialLink";

export interface SocialsProps extends BoxProps {
  inverse?:boolean;
}

export const Socials = (props: SocialsProps) => {
  const { inverse, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Flex
        gridGap={{ base: 3 }}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        <SocialLink
        inverse={inverse}
          icon={<TiSocialFacebook />}
          href="https://www.facebook.com/groups/185264503454060/"
        />
        <SocialLink
        inverse={inverse}
          icon={<TiSocialInstagram />}
          href="https://www.instagram.com/prenatbaby"
        />
      </Flex>
    </Box>
  );
};
