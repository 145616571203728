import { Box } from '@chakra-ui/layout';
import React from 'react';
import { Container } from '../../Container/Container';
import { FooterCompany } from './FooterCompany';

export interface FooterTopProps {
    className?: string;
}

export const FooterTop = (props: FooterTopProps) => {
    const { className = '' } = props;
    return (
        <Container>
            <Box py={10}>
                <FooterCompany />
            </Box>
        </Container>
    );
};
