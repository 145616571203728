export interface Route {
  label: string;
  to: string;
  showInMenu?: boolean;
}

const asRoute = (route: Route): Route => route;

export const routes = {
  about: asRoute({
    label: "O nás",
    to: "#o-nas"
  }),
  courses: asRoute({
    label: "Predpôrodné kurzy",
    to: "#predporodne-kurzy"
  }),
  consulting: asRoute({
    label: "Laktačné poradenstvo",
    to: "#laktacne-poradenstvo"
  }),
  blog: asRoute({
    label: "Blog",
    to: "blog"
  })
};
