import { globalHistory } from "@reach/router";
import React from "react";
import { routes } from "../../../../lib/routes";
import { Container } from "../../Container/Container";
import { LogoLink } from "../../LogoLink/LogoLink";
import { addAbsolutePathIfNotScrollNav } from "../../Navbar/components/NavbarItems";

export interface MobileMenuOverlayProps {
  className?: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

const renderMobileMenuLinkItems = (path: string) =>
  Object.values(routes)
    .filter((item) => item?.showInMenu != false)
    .map((route) => {
      const to = addAbsolutePathIfNotScrollNav(path, route.to);
      return (
        <div
          key={route.to}
          className="uppercase"
          onClick={() => {
            window.location.replace(to);
            if (to[0] === "#") {
              window.location.reload();
            }
          }}
        >
          {route.label}
        </div>
      );
    });

const MobileMenuOverlayInner = (props: MobileMenuOverlayProps) => {
  const path = globalHistory.location.pathname;

  return (
    <div
      className={`${
        props.open ? "left-0" : "-left-full"
      } fixed top-0  bg-white h-screen w-screen z-40 transition-all duration-200 py-4`}
    >
      <Container>
        <div className="flex items-center space-x-5 py-4">
          <LogoLink />
        </div>
        <div className="flex justify-center flex-col items-center space-y-4 mt-10 font-semibold text-2xl">
          {renderMobileMenuLinkItems(path)}
        </div>
      </Container>
    </div>
  );
};

export const MobileMenuOverlay = MobileMenuOverlayInner;
