import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { BsTelephone } from "react-icons/bs";
import { ContactLink } from "../../ContactLinks/components/ContactLink";
import { Container } from "../../Container/Container";
import { MobileMenu } from "../../Header/components/MobileMenu";
import { LogoLink } from "../../LogoLink/LogoLink";
import { NavbarItems } from "./NavbarItems";

const NavbarBottom = () => {
  return (
    <Box bg="white" boxShadow="2xl" fixed>
      <Container position="relative">
        <div className="hidden md:flex">
          <Flex py={6} w="full" justifyContent="center">
            <NavbarItems />
          </Flex>
        </div>

        <Flex
          py={4}
          w="full"
          justifyContent={{ base: "space-between", md: "flex-start" }}
          position={{ base: "relative", md: "absolute" }}
          top={0}
          alignItems="center"
        >
          <LogoLink />
          <div className="md:hidden">
            <ContactLink
              fontSize="sm"
              color={"primary"}
              icon={<BsTelephone />}
              text="+421 948 404 993"
              href="tel:+421948404993"
            />
          </div>
          <div className="md:hidden">
            <MobileMenu />
          </div>
        </Flex>
      </Container>
    </Box>
  );
};

export default NavbarBottom;
