import { Box, BoxProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export interface SocialLinkProps extends BoxProps {
  icon: ReactNode;
  href: string;
  inverse?: boolean;
}

export const SocialLink = (props: SocialLinkProps) => {
  const { inverse, icon, href, ...restOfProps } = props;
  return (
    <Box
      zIndex={2}
      {...restOfProps}
      _hover={{ transform: "scale(1.03)", transition: "all 300ms" }}
      fontSize={{ base: "md", md: "md", lg: "md" }}
    >
      <a href={href} target="_blank">
        <Box p={1} bg={inverse ? "primary" : "white"} rounded="full">
          <Box color={inverse ? "white" : "primary"} fontSize="xl">
            {icon}
          </Box>
        </Box>
      </a>
    </Box>
  );
};
