import {
  Container as ChakraContainer,
  ContainerProps as ChakraContainerProps
} from "@chakra-ui/layout";
import React, { ReactNode } from "react";

export interface ContainerProps extends ChakraContainerProps {
  className?: string;
  children?: ReactNode;
}

export const Container = (props: ContainerProps) => {
  const { className = "", children, ...restOfProps } = props;
  return (
    <ChakraContainer maxW="container.xl" {...restOfProps}>
      {children}
    </ChakraContainer>
  );
};
