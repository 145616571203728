import { Box, BoxProps } from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";

export interface NavbarItemProps extends BoxProps {
  label: string;
  href: string;
}

export const NavbarItem = (props: NavbarItemProps) => {
  const { label, href, ...restOfProps } = props;

  return (
    <Box
      {...restOfProps}
      fontWeight={600}
      fontSize="xl"
      zIndex={10}
      textTransform="uppercase"
      textDecoration="none"
    >
      {href.includes("#") ? (
        <a href={href}>{label}</a>
      ) : (
        <Link to={href}>{label}</Link>
      )}
    </Box>
  );
};
