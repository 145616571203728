const primaryColor = "rgba(2,48,139,0.97)";

export const theme = {
  name: "Default",
  color: {
    primary: "#89B222",
    secondary: "#454F4E",
    bg: "#F7F7F7",
    grey: "#918E8F",
    text: "#434242"
  },
  borderRadius: "16px",
  spacing: {
    sectionMarginTop: "14rem"
  },
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xlg: 1600
  }
};
